import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';

import { environment } from 'environments/environment';

import { Observable } from 'rxjs';

import { MemberRoutineRequestModel } from './routine.request-model';
import { MemberRoutineResponseModel } from './routine.response-model';

@Injectable
(
	{
		providedIn: 'root'
	}
)

export class MemberRoutineService
{
	private http = inject(HttpClient);


	public get(routineId: string) : Observable<MemberRoutineResponseModel>
	{
		const url = `${ environment.baseUrl  }/member/training/routine/${ routineId }`

		return this.http.get<MemberRoutineResponseModel>(url);
	}

	public getAll(routineType: number) : Observable<MemberRoutineResponseModel[]>
	{
		const url = `${ environment.baseUrl  }/member/training/routines?routineType=${ routineType }`

		return this.http.get<MemberRoutineResponseModel[]>(url);
	}

	public create(request: MemberRoutineRequestModel) : Observable<MemberRoutineResponseModel>
	{
		const url = `${ environment.baseUrl  }/member/training/routine`

		return this.http.post<MemberRoutineResponseModel>(url, request);
	}

	public update(request: MemberRoutineRequestModel) : Observable<MemberRoutineResponseModel>
	{
		const url = `${ environment.baseUrl  }/member/training/routine/${ request.routineId }`

		return this.http.put<MemberRoutineResponseModel>(url, request);
	}

	public updateRange(request: MemberRoutineRequestModel[]) : Observable<MemberRoutineResponseModel[]>
	{
		const url = `${ environment.baseUrl  }/member/training/routines`

		return this.http.put<MemberRoutineResponseModel[]>(url, request);
	}
}
