import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';

import { environment } from 'environments/environment';

import { Observable } from 'rxjs';

import { MemberWorkoutResistanceExerciseSetTargetRequestModel } from './workout-resistance-exercise-set-target.request-model';
import { MemberWorkoutResistanceExerciseSetTargetResponseModel } from './workout-resistance-exercise-set-target.response-model';

@Injectable
(
	{
		providedIn: 'root'
	}
)

export class MemberWorkoutResistanceExerciseSetTargetService
{
	private http = inject(HttpClient);


	public getByWorkoutResistanceExerciseId(workoutResistanceExerciseId: string): Observable<MemberWorkoutResistanceExerciseSetTargetResponseModel[]>
	{
		const url = `${ environment.baseUrl  }/member/training/workout-resistance-exercise-set-targets/workout-resistance-exercise/${ workoutResistanceExerciseId }`;

		return this.http.get<MemberWorkoutResistanceExerciseSetTargetResponseModel[]>(url);
	}

	public create(request: MemberWorkoutResistanceExerciseSetTargetRequestModel): Observable<MemberWorkoutResistanceExerciseSetTargetResponseModel>
	{
		const url = `${ environment.baseUrl  }/member/training/workout-resistance-exercise-set-target`;

		return this.http.post<MemberWorkoutResistanceExerciseSetTargetResponseModel>(url, request);
	}

	public delete(workoutResistanceExerciseSetTargetId: string): Observable<MemberWorkoutResistanceExerciseSetTargetResponseModel>
	{
		const url = `${ environment.baseUrl  }/member/training/workout-resistance-exercise-set-target/${ workoutResistanceExerciseSetTargetId }`;

		return this.http.delete<MemberWorkoutResistanceExerciseSetTargetResponseModel>(url);
	}

	public update(request: MemberWorkoutResistanceExerciseSetTargetRequestModel): Observable<MemberWorkoutResistanceExerciseSetTargetResponseModel>
	{
		const url = `${ environment.baseUrl  }/member/training/workout-resistance-exercise-set-target/${ request.workoutResistanceExerciseSetTargetId }`;

		return this.http.put<MemberWorkoutResistanceExerciseSetTargetResponseModel>(url, request);
	}

	public updateRange(request: MemberWorkoutResistanceExerciseSetTargetRequestModel[]): Observable<MemberWorkoutResistanceExerciseSetTargetResponseModel[]>
	{
		const url = `${ environment.baseUrl  }/member/training/workout-resistance-exercise-set-targets`

		return this.http.put<MemberWorkoutResistanceExerciseSetTargetResponseModel[]>(url, request);
	}
}
